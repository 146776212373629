class Urls {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;

        this.user = {
            login: baseUrl + "users/login",
            logout: baseUrl + "users/logout",
            verifyToken: baseUrl + "users/verify-token",
            getOne: baseUrl + "users/user",
            getAll: baseUrl + "users",
            verifyCaptcha: baseUrl + "users/verify-captcha",
            loginWithGoogle: baseUrl + "users/auth/google", // Google login
        }

        this.dictionary = {
            dictionary_eng_to_heb: baseUrl + "dictionary/dictionary_eng_to_heb/",
            dictionary_heb_to_eng: baseUrl + "dictionary/dictionary_heb_to_eng/",
        };

        this.categories = {
            category: baseUrl + "category/",
        };

        this.dictionaryLog = {
            getAll: baseUrl + "dictionary-log",
        };

        this.payment = {
            createSubscription_step1: baseUrl + "payment/paypal/subscription/create/",
            createSubscription_step2: baseUrl + "payment/paypal/subscription/open/",
            cancelSubscription: baseUrl + "payment/paypal/subscription/cancel",
        };
    }
}

class DevelopmentUrls extends Urls {
    constructor() {
        super("http://localhost:3001/api/"); // Development server
    }
}

class ProductionUrls extends Urls {
    constructor() {
        // super("https://54.92.224.65/api/"); // Test server
        // super("https://translix.net/api/"); // Test server
        super("https://test.lexillon.co.il/api/"); // Test server

        // super("https://translix.org/api/"); // Production server
        // super("https://main.lexillon.co.il/api/"); // Production server
    }
}

const urls = process.env.NODE_ENV === "development" ? new DevelopmentUrls() : new ProductionUrls();

export default urls;
