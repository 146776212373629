import axios from 'axios';
import urls from './Urls';
import { action, makeObservable, observable, runInAction } from "mobx";
import { sortByField, filter, wait } from '../Utils/dictionary';
import groups from './Groups';
import dictionaryLog from './DictionaryLog';
import { containsErrorKey } from '../Utils/error';

class Dictionary {
    dictionary_eng_to_heb = [];
    dictionary_heb_to_eng = [];

    dictionary_eng_to_heb_fields = [
        '_id',
        'original',
        // 'originalWithoutPunctuation',
        'trns',

        'categories', // Each object in the array contains: _id, name
        'pos',
        'latin',
        'olami',
        'band',
        'priority',

        'abbreviation',
        'alternative',
        'pl',
        'informal',
        'pastSimple',
        'pastPerfect',
        'adjMore',
        'adjMost',
        'expPlural',
        'opposite',

        'createdAt',
        'updatedAt',

        // 'picture'
    ];

    dictionary_heb_to_eng_fields = [
        '_id',
        'original',
        // 'originalWithoutPunctuation',
        'value',
        'trns',

        'categories', // Each object in the array contains: _id, name
        'pos',
        'latin',
        'olami',
        'band',
        'priority',

        'abbreviation',
        'gender',
        'root',
        'family',
        'femaleform',
        'pluralform',
        'newHebrew',
        'latinHebrew',
        'amrBre',
        'opposite',

        'createdAt',
        'updatedAt',

        // 'picture'
    ];

    search = { text: '', field: 'all' };

    current = {
        dictionaryType: '',
        data: [],
        fields: [],
        sum: 0,
        value: { _id: '', index: -1 },
        sort: {
            field: localStorage.getItem('sortField') || 'originalWithoutPunctuation',
            order: localStorage.getItem('sortOrder') || 'asc'
        },
    };

    isLoading = {
        all: true,
        dictionary_eng_to_heb: { loaded: 0, total: 0 },
        dictionary_heb_to_eng: { loaded: 0, total: 0 },
        updateDictionary: 'success'
    };

    isFiltering = false;

    // ______________________________________________________
    constructor() {
        makeObservable(this, {
            dictionary_eng_to_heb: observable,
            dictionary_heb_to_eng: observable,
            dictionary_eng_to_heb_fields: observable,
            dictionary_heb_to_eng_fields: observable,

            search: observable,
            current: observable,

            isLoading: observable,
            isFiltering: observable,

            getAll: action,
            getOne: action,
            create: action,
            update: action,
            delete: action,

            setCurrent: action,
            setIsLoading: action,
            clear: action
        });
        this.getAll();
    }

    // ______________________________________________________
    // CRUD - Create, Read, Update, Delete
    async getAll() {
        if (this.dictionary_eng_to_heb.length === 0 || this.dictionary_heb_to_eng.length === 0) {

            await wait(1000); // Wait for the "createInterceptors" to load

            groups.getCategories();
            dictionaryLog.getLogs();

            // Load dictionaries in parallel
            const [responseEng, responseHeb] = await Promise.all([
                axios.get(urls.dictionary.dictionary_eng_to_heb, {
                    withCredentials: true,
                    // Progress bar for English dictionary
                    onDownloadProgress: progressEvent => {
                        const loadedEngMB = (progressEvent.loaded / 1024 / 1024).toFixed(2);
                        const totalEngMB = (progressEvent.total / 1024 / 1024).toFixed(2);
                        this.isLoading.dictionary_eng_to_heb.loaded = loadedEngMB;
                        this.isLoading.dictionary_eng_to_heb.total = totalEngMB;
                    }
                }),
                axios.get(urls.dictionary.dictionary_heb_to_eng, {
                    withCredentials: true,
                    // Progress bar for Hebrew dictionary
                    onDownloadProgress: progressEvent => {
                        const loadedHebMB = (progressEvent.loaded / 1024 / 1024).toFixed(2);
                        const totalHebMB = (progressEvent.total / 1024 / 1024).toFixed(2);
                        this.isLoading.dictionary_heb_to_eng.loaded = loadedHebMB;
                        this.isLoading.dictionary_heb_to_eng.total = totalHebMB;
                    }
                })
            ]);

            const dictionaryDataEng = responseEng.data;
            const dictionaryDataHeb = responseHeb.data;

            // Simulate complete load for both dictionaries
            setTimeout(() => {
                this.isLoading.dictionary_eng_to_heb.loaded = this.isLoading.dictionary_eng_to_heb.total;
                this.isLoading.dictionary_heb_to_eng.loaded = this.isLoading.dictionary_heb_to_eng.total;
            }, 200);

            runInAction(async () => {
                this.dictionary_eng_to_heb = dictionaryDataEng;
                this.dictionary_heb_to_eng = dictionaryDataHeb;
                await sortByField(this.dictionary_eng_to_heb);
                await sortByField(this.dictionary_heb_to_eng);
                groups.countValuesPerCategory('dictionary_eng_to_heb');
                groups.countValuesPerCategory('dictionary_heb_to_eng');
                groups.getGroups('dictionary_eng_to_heb');
                groups.getGroups('dictionary_heb_to_eng');
                this.setCurrent(null, null, null, null, this.current.sort.field, this.current.sort.order);

                let countdown = 3;
                const countdownInterval = setInterval(() => {
                    if (countdown > 0) {
                        this.isLoading.all = countdown;
                        countdown--;
                    } else {
                        clearInterval(countdownInterval);
                        this.isLoading.all = false;
                    }
                }, 1000);
            });
        }
    }

    getOne(_id) {
        const dictionaryType = this.current.dictionaryType;
        return this[dictionaryType].find(entry => entry._id === _id);
    }

    async create(value) {
        try {
            this.isLoading.updateDictionary = 'loading';

            const dictionaryType = this.current.dictionaryType;
            // Create the entry
            const response = await axios.post(urls.dictionary[dictionaryType],
                { ...value, categories: value.categories?.map(cat => cat.value) },
                { withCredentials: true });

            const log = response.data;
            const newValue = log.content.value;

            // Transform the categories array
            const transformedCategories = value.categories.map(category => ({
                name: category.label,
                _id: category.value
            }));
            newValue.categories = transformedCategories;

            // Add the new entry to the dictionary
            runInAction(async () => {
                this[dictionaryType].push(newValue);
                dictionaryLog.addLog(log);
                await sortByField(this[dictionaryType], this.current.sort.field, this.current.sort.order);
                this.setCurrent(null, null, newValue._id);
                groups.countValuesPerCategory(dictionaryType);
            });

            if (containsErrorKey(log)) // Error creating audio
                this.isLoading.updateDictionary = 'error';
            else this.isLoading.updateDictionary = 'success';

            return response.data;
        } catch (error) { // Error creating dictionary value
            const log = error.response.data;
            dictionaryLog.addLog(log);
            if (containsErrorKey(log))
                this.isLoading.updateDictionary = 'error';
            throw error;
        }
    }

    async update(_id, value) {
        try {
            this.isLoading.updateDictionary = 'loading';

            const dictionaryType = this.current.dictionaryType
            // Update the entry
            const response = await axios.patch(urls.dictionary[dictionaryType] + _id,
                { ...value, categories: value.categories?.map(cat => cat.value) },
                { withCredentials: true });

            const log = response.data;
            const updateValue = log.content.value.updatedValue;

            // Transform the categories array
            const transformedCategories = value.categories?.map(category => ({
                name: category.label,
                _id: category.value
            }));
            updateValue.categories = transformedCategories;

            // Update the dictionary
            runInAction(async () => {
                let index = this[dictionaryType].findIndex(v => v._id === _id);
                this[dictionaryType][index] = updateValue;
                dictionaryLog.addLog(log);
                await sortByField(this[dictionaryType], this.current.sort.field, this.current.sort.order);
                this.setCurrent(null, null, updateValue._id);
                groups.countValuesPerCategory(dictionaryType);
            });

            if (containsErrorKey(log)) // Error updating audio
                this.isLoading.updateDictionary = 'error';
            else this.isLoading.updateDictionary = 'success';

            return response.data;
        } catch (error) { // Error updating dictionary value
            const log = error.response.data;
            dictionaryLog.addLog(log);
            if (containsErrorKey(log))
                this.isLoading.updateDictionary = 'error';
            throw error;
        }
    }

    async delete(_id) {
        try {
            this.isLoading.updateDictionary = 'loading';

            const dictionaryType = this.current.dictionaryType
            const response = await axios.delete(urls.dictionary[dictionaryType] + _id, {
                withCredentials: true
            });

            const log = response.data;
            const status = response.status;

            if (status === 200) {
                runInAction(() => {
                    const index = this[dictionaryType].findIndex(v => v._id === _id);
                    this[dictionaryType].splice(index, 1);
                    dictionaryLog.addLog(log);
                    this.setCurrent(null, null, null);
                    groups.countValuesPerCategory(dictionaryType);
                });

                if (containsErrorKey(log)) // Error deleting audio
                    this.isLoading.updateDictionary = 'error';
                else this.isLoading.updateDictionary = 'success';

                return response.data;
            }
        } catch (error) { // Error deleting dictionary value
            const log = error.response.data;
            dictionaryLog.addLog(log);
            if (containsErrorKey(log))
                this.isLoading.updateDictionary = 'error';
            throw error;
        }
    }

    // ______________________________________________________
    async setCurrent(dictionaryType, search, current_id, current_index, sortField, sortOrder) {
        // set dictionaryType
        if (dictionaryType)
            this.current.dictionaryType = dictionaryType;
        else if (!this.current.dictionaryType)
            this.current.dictionaryType = 'dictionary_eng_to_heb';

        // set fields
        if (dictionaryType)
            this.current.fields = this[`${dictionaryType}_fields`];
        else
            this.current.fields = this[`${this.current.dictionaryType}_fields`];

        // set data
        if (search) {
            this.search = search;

            this.isFiltering = true;
            this.current.data = await filter(this[this.current.dictionaryType], search);
            this.isFiltering = false;
        }
        else {
            this.isFiltering = true;
            this.current.data = await filter(this[this.current.dictionaryType], this.search);
            this.isFiltering = false;
        }

        // set sum
        this.current.sum = this.current.data.length;

        // set value._id
        if (current_id)
            this.current.value._id = current_id;

        // set value.index
        if (current_index)
            this.current.value.index = current_index;
        else {
            const index = this.current.data.findIndex(v => v._id === this.current.value._id);
            this.current.value.index = index;
        }

        // set sortField and sortOrder
        if (sortField && sortOrder) {
            this.current.sort.field = sortField;
            this.current.sort.order = sortOrder;
            sortByField(this.dictionary_eng_to_heb, sortField, sortOrder);
            sortByField(this.dictionary_heb_to_eng, sortField, sortOrder);

            localStorage.setItem('sortField', sortField);
            localStorage.setItem('sortOrder', sortOrder);
        }
    }

    setIsLoading(status) {
        this.isLoading.updateDictionary = status;
    }

    clear() {
        this.dictionary_eng_to_heb = [];
        this.dictionary_heb_to_eng = [];

        this.search = { text: '', field: 'all' };

        this.current = {
            dictionaryType: '',
            data: [],
            fields: [],
            sum: 0,
            value: { _id: '', index: -1 },
            sort: {
                field: localStorage.getItem('sortField') || 'originalWithoutPunctuation',
                order: localStorage.getItem('sortOrder') || 'asc'
            },
        };

        this.isLoading = {
            all: true,
            dictionary_eng_to_heb: { loaded: 0, total: 0 },
            dictionary_heb_to_eng: { loaded: 0, total: 0 },
            updateDictionary: 'success'
        };
    }
}

const dictionary = new Dictionary();
export default dictionary;